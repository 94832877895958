import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import HeroSubpage from "components/HeroSubpage"

import { Contact } from "page_components/home"
import { ServicesRedirect } from "page_components/team"
import { Content, Milestones } from "page_components/about-us"

const AboutPage = ({ data, location }) => {
  const pageData = data.allWpPage.nodes[0]

  return (
    <Layout
      seo={{
        title: pageData.title,
      }}
      location={location}
    >
      <HeroSubpage
        title={pageData.title}
        description={pageData.acfAbout.heroDescription}
        backgroundImage={
          require("assets/images/about-us/about-us-hero-bg.svg").default
        }
      />
      <Content pageData={pageData} />
      <Milestones pageData={pageData} />
      <ServicesRedirect />
      <Contact isSubpage />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo0Mg==" } }) {
      nodes {
        id
        acfAbout {
          contentDescription
          contentHeader
          heroDescription
          milestonesHeader
          milestonesItems {
            year
            content {
              description
              header
            }
          }
        }
        title
      }
    }
  }
`

export default AboutPage
