import "./styles.scss"

import React from "react"

const Milestones = ({ pageData }) => {
  return (
    <section className="milestones">
      <div className="container-fluid">
        <h2>{pageData?.acfAbout?.milestonesHeader}</h2>
        {pageData?.acfAbout?.milestonesItems?.map((milestone, index) => (
          <div className="milestones__item" key={index}>
            <div className="row">
              <div className="col-md-2">
                <h4 className="milestones__item-header">{milestone?.year}</h4>
              </div>
              <div className="col-md-10">
                <div className="milestones__item-row">
                  {milestone?.content?.map((item, index) => (
                    <div key={index}>
                      <h4>{item?.header}</h4>
                      <div
                        dangerouslySetInnerHTML={{ __html: item?.description }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Milestones
