import "./styles.scss"

import React from "react"

const AboutContent = ({ pageData }) => {
  return (
    <div className="about-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-content__image">
              <div className="about-content__image-caption">
                <h3>{pageData?.acfAbout?.contentHeader}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-4 offset-lg-1">
            <div
              className="about-content__description"
              dangerouslySetInnerHTML={{
                __html: pageData?.acfAbout?.contentDescription,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutContent
